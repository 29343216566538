<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <v-preview
      v-if="isPreview"
      :show="isPreview"
      :closePreivew="closePreview"
      :data="previewObject"
    />
    <alert-confirm
      :show="isDelete"
      title="Remove Confirmation"
      subTitle="Are you sure you want to remove this Department? This action will remove content in both Thai and English."
      :close="closeDeletePopup"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDelete"
      icon=""
    ></alert-confirm>
    <alert-confirm
      :show="isDeleteError"
      title="Department cannot be deleted."
      subTitle="This department cannot be deleted because there is Doctor under this branch."
      :close="closeDeleteErrorDialog"
      headline-color="black"
      :confirm="closeDeleteErrorDialog"
      icon=""
      buttonCancel="OK"
    ></alert-confirm>
    <alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></alert-confirm>
    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Department
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete()"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-preview="disabledPreview"
            :on-remove="onRemove"
            :on-cancel="showCancelPopup"
            :on-preview="onPreview"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Department Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            color="primary"
            v-model="departmentObject.status"
            :disabled="disabledEdit"
            :false-value="1"
            :true-value="0"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="6" t-data="info-input">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch <span class='danger500Color--text'>*</span>"
                :isDisabled="disabledEdit"
                :branch="branches"
                :selectedBranch="selectedBranch"
                :syncData="changedSelectedBranch"
                :isEditMode="isEditMode"
                color="primary"
                v-if="!isLoading"
              ></single-selected-branch>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Department Code
              </span>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :placeholder="disabledEdit ? '' : 'Input Department Code'"
                v-model="departmentObject.code"
                :disabled="disabledEdit"
                :error-messages="codeErrorMessage"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="6" t-data="info-input">
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Symptomatic Area <span class="danger500Color--text">*</span>
              </span>
              <v-combobox
                placeholder="Input Symptomatic Area"
                v-model="selectedSymptomaticItems"
                @input="handleInputSymptomatics"
                @change="changedSymptomatics"
                multiple
                outlined
                dense
                hide-details
                :items="symptomaticItems"
                :rules="[validateSymptomatic]"
                item-text="text"
                item-value="value"
                variant="solo"
                clearable
                :readonly="disabledEdit || disableEmptyBranch"
                :class="{
                  'symptomatic-box-error': symptomaticError
                }"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeSymptomaticItem(item)"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit"
                    clearable
                  >
                    {{ item.text }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
              <div
                t-data="error-message"
                class="flex-nowrap"
                v-if="symptomaticError"
              >
                <p class="edit-error-message sub-body-regular mb-0">
                  {{ symptomaticError }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" t-data="info-input">
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Tags
              </span>
              <v-combobox
                class="combobox-tag can-disable-selector"
                v-model="selectedTags"
                placeholder="Input Title Tags"
                @input="handleInputTags"
                multiple
                outlined
                dense
                hide-details
                :disabled="disabledEdit"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeTag(item)"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit"
                  >
                    {{ item }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="6" t-data="info-input">
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Phone Number
              </span>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit"
                v-model="departmentObject.contact_number"
                :placeholder="disabledEdit ? '' : 'Input Phone Number'"
              ></v-text-field>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Office Hours
              </span>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit"
                v-model="departmentObject.office_hour"
                :placeholder="disabledEdit ? '' : 'Input Office Hours'"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card class="border-card">
            <v-card-text>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6">
                  <h4 class="mb-6 card-title" t-data="info-title">
                    Thai Content
                  </h4>
                </v-col>
                <v-col cols="3">
                  <h4 class="mb-6 card-title" t-data="info-title">
                    English Content
                  </h4>
                </v-col>
                <v-col t-data="checkbox-duplicate">
                  <v-checkbox
                    v-model="duplicateContent.department"
                    :disabled="disabledEdit"
                    hide-details
                    label="Duplicate Thai content"
                    color="secondary"
                    class="check-box-text body-regular mt-0 pt-0"
                    @click="duplicateThaiContent"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Alias <span class="danger500Color--text">*</span>
                  </p>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disableAlias"
                    required="true"
                    :rules="[aliasRules.required]"
                    v-model="departmentObject.alias"
                    :placeholder="disabledEdit ? '' : 'Input Alias'"
                    :hide-details="!isAliasError"
                    @change="setCheckedAlias"
                    @keypress="isNotSpecialChar"
                    @paste="pasteData"
                  ></v-text-field>
                  <v-row
                    align="center"
                    justify="start"
                    class="flex-nowrap"
                    t-data="permalink-container"
                    no-gutters
                  >
                    <p
                      class="value-message small mb-0 secondaryTextColor--text"
                    >
                      Permalink :
                      <a @click="clickPackageLink">{{ pageLink }}</a>
                      <a></a>
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Alias <span class="danger500Color--text">*</span>
                  </p>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    disabled
                    required="true"
                    :rules="[aliasRules.required]"
                    v-model="departmentObject.alias"
                    :placeholder="disabledEdit ? '' : 'Input Alias'"
                    :hide-details="!isAliasError"
                  ></v-text-field>
                  <v-row
                    align="center"
                    justify="start"
                    class="flex-nowrap"
                    t-data="permalink-container"
                    no-gutters
                  >
                    <p
                      class="value-message small mb-0 secondaryTextColor--text"
                    >
                      Permalink :
                      <a @click="clickPackageLink">{{ pageLink }}</a>
                      <a></a>
                    </p>
                  </v-row>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Location
                  </span>
                  <v-textarea
                    class="can-disable-selector"
                    placeholder="Input Description"
                    outlined
                    dense
                    required
                    rows="2"
                    row-height="20"
                    v-model="departmentObject.contents[0].location"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Location
                  </span>
                  <v-textarea
                    class="can-disable-selector"
                    placeholder="Input Description"
                    outlined
                    dense
                    required
                    rows="2"
                    row-height="20"
                    v-model="departmentObject.contents[1].location"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Department Name <span class="danger500Color--text">*</span>
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameThRules"
                    v-model="departmentObject.contents[0].title"
                    :placeholder="disabledEdit ? '' : 'Input Department Name'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Department Name <span class="danger500Color--text">*</span>
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameEnRules"
                    v-model="departmentObject.contents[1].title"
                    :placeholder="disabledEdit ? '' : 'Input Department Name'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
                <v-col cols="4" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Logo
                  </p>
                  <image-dropzone
                    subtitle="Recommended size 40x40px."
                    subtitle-font-class="small-bold"
                    content-font-class="small"
                    class="can-disable-selector"
                    :thumbnail="departmentObject.contents[0].logo"
                    :is-error="isImageError"
                    :error-msg="imageErrorMessage"
                    :disabled="disabledEdit"
                    @update="updateImageThai"
                    @remove="removeImageThai"
                  ></image-dropzone>
                </v-col>
                <v-col cols="2" t-data="info-input"> </v-col>
                <v-col cols="4" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Logo
                  </p>
                  <image-dropzone
                    subtitle="Recommended size 40x40px."
                    subtitle-font-class="small-bold"
                    content-font-class="small"
                    class="can-disable-selector"
                    :thumbnail="departmentObject.contents[1].logo"
                    :is-error="isImageErrorEn"
                    :error-msg="imageErrorMessageEn"
                    :disabled="disabledEdit"
                    @update="updateImageEn"
                    @remove="removeImageEn"
                  ></image-dropzone>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Alt Text
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    v-model="departmentObject.contents[0].logo_alt"
                    :placeholder="disabledEdit ? '' : 'Alt Text'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Alt Text
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    v-model="departmentObject.contents[1].logo_alt"
                    :placeholder="disabledEdit ? '' : 'Alt Text'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Cover Image <span class="danger500Color--text">*</span>
                  </p>
                  <image-dropzone
                    subtitle="Recommended size 40x40px."
                    subtitle-font-class="small-bold"
                    content-font-class="small"
                    class="can-disable-selector"
                    :thumbnail="departmentObject.contents[0].cover_image"
                    :is-error="isCoverImageError"
                    :error-msg="coverImageErrorMessage"
                    :disabled="disabledEdit"
                    @update="updateCoverImage"
                    @remove="removeCoverImage"
                  ></image-dropzone>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Cover Image <span class="danger500Color--text">*</span>
                  </p>
                  <image-dropzone
                    subtitle="Recommended size 40x40px."
                    subtitle-font-class="small-bold"
                    content-font-class="small"
                    class="can-disable-selector"
                    :thumbnail="departmentObject.contents[1].cover_image"
                    :is-error="isCoverImageErrorEn"
                    :error-msg="coverImageErrorMessageEn"
                    :disabled="disabledEdit"
                    @update="updateCoverImageEn"
                    @remove="removeCoverImageEn"
                  ></image-dropzone>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Alt Text
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    v-model="departmentObject.contents[0].cover_image_alt"
                    :placeholder="disabledEdit ? '' : 'Alt Text'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Alt Text
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    v-model="departmentObject.contents[1].cover_image_alt"
                    :placeholder="disabledEdit ? '' : 'Alt Text'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Description
                  </p>
                  <quill-editor
                    ref="quillTh"
                    v-model="departmentObject.contents[0].description"
                    :options="editorOption"
                  ></quill-editor
                ></v-col>
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Description
                  </p>

                  <quill-editor
                    ref="quillEn"
                    v-model="departmentObject.contents[1].description"
                    :options="editorOption"
                  ></quill-editor
                ></v-col>
              </v-row>
              <meta-content
                :key="`meta-content-${componentKey}`"
                :disabledEdit="disabledEdit"
              ></meta-content>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card class="mt-6">
      <v-row class="align-center mb-4 mx-4 pt-6" style="margin-left: 1px">
        <h6 class="pageHeadline" t-data="page-headline">Content Repeater</h6>
        <v-spacer></v-spacer>
        <v-btn
          id="add-btn"
          large
          color="primary600Color"
          class="white-text-button button-bold"
          t-data="add-btn"
          :disabled="!isEditMode"
          @click="addRepeater"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>ADD REPEATER
        </v-btn>
      </v-row>
      <v-data-table
        class="elevation-1"
        :loading="isLoadingContentRepeater"
        :items="contentRepeaterList"
        :headers="contentRepeaterHeaders"
        no-data-text="Please complete creating the Repeater."
      >
        <template style="width: 418px">
          <tooltip-title>
            <template v-slot:content>
              <v-list-item-content
                class="d-inline-block text-truncate"
                style="width: 418px"
              >
                <v-list-item-title> </v-list-item-title>
                <v-list-item-subtitle> </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </tooltip-title>
        </template>
      </v-data-table>
    </v-card>
    <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px">
      <created-detail v-if="isEditMode" :data-object="departmentObject" />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete()"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-preview="disabledPreview"
        :on-remove="onRemove"
        :on-cancel="showCancelPopup"
        :on-preview="onPreview"
        :on-save="onSave"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor, Quill} from 'vue-quill-editor'
import {mapState, mapActions} from 'vuex'

import PopupLoading from '@/components/PopupLoading'
import AlertSuccess from '@/components/AlertSuccess'
import moment from 'moment'
import ImageUploader from 'quill-image-uploader'
import MyLink from '@/constants/quill-link'
import Preview from '@/components/DepartmentPreview'
import {
  hasPermissionsByOrgs,
  isUrl,
  convertFileToBase64,
  // convertSelectedData,
  isAliasAllow,
  isEnglishAlphabet,
  isThaiAlphabet
} from '@/helper/helper'
import MetaContent from '@/components/department/MetaContent'

Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)

import AlertConfirm from '@/components/AlertConfirm'
import CreatedDetail from '@/components/CreatedDetail'
import EditButtons from '@/components/EditButtons'
import ImageDropzone from '@/components/ImageDropzone'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import {actionEnum} from '@/constants/constants'

export default {
  components: {
    quillEditor,
    'v-alert-success': AlertSuccess,
    'v-popup-loading': PopupLoading,
    'v-preview': Preview,
    AlertConfirm,
    CreatedDetail,
    EditButtons,
    ImageDropzone,
    SingleSelectedBranch,
    MetaContent
  },
  name: 'Edit',
  data() {
    return {
      datePicker: false,
      isEditMode: false,
      valid: true,
      isLoad: false,
      isDeleteError: false,
      isPreview: false,
      isCancel: false,
      title_th: '',
      title_en: '',

      // images
      isImageError: false,
      imageErrorMessage: '',
      isImageErrorEn: false,
      imageErrorMessageEn: '',
      isCoverImageErrorEn: false,
      coverImageErrorMessageEn: '',
      isCoverImageError: false,
      coverImageErrorMessage: '',

      description_th: '',
      description_en: '',
      categoryId: '',
      statusId: '',
      isError: false,
      isSuccess: false,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      },
      nameThRules: [v => !!v || 'Department Name is required'],
      nameEnRules: [v => !!v || 'Department Name is required'],
      isAliasError: true,
      aliasRules: {
        required: value => {
          if (value) {
            this.isAliasError = false
            return true
          }
          this.isAliasError = true
          return 'Alias is required'
        }
      },
      linkroute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'departments',
          disabled: false,
          href: '/department-list'
        },
        {
          text: this.textMode,
          disabled: true,
          href: '/'
        }
      ],
      date: moment(new Date())
        .add(1, 'days')
        .toISOString()
        .substr(0, 10),
      contentRepeaterHeaders: [
        {
          text: 'Content Repeater Title',
          align: 'left',
          sortable: false,
          value: 'content',
          width: 418
        },
        {
          text: 'Action',
          align: 'left',
          sortable: false,
          value: 'action',
          width: 143
        }
      ],
      selectedTags: [],
      selectedSymptomaticItems: [],
      componentKey: 0,
      symptomaticError: ''
    }
  },

  computed: {
    disableEmptyBranch() {
      if (
        !this.departmentObject.org_codename &&
        this.departmentObject.org_codename !== null
      ) {
        return true
      }
      return false
    },
    hospitalName() {
      return this.orgObject.filter(
        o => o.value === this.departmentObject.org_codename
      )[0].text
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditDepartment', [
          this.departmentObject.org_codename
        ])
      )
    },
    disableAlias() {
      return !this.filterHospital
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.departmentObject.org_codename !== null) {
        selectedBranch = this.departmentObject.org_codename
      }
      return selectedBranch
    },
    previewObject() {
      return {
        organization: {
          name: this.orgObject.filter(
            o => o.value === this.departmentObject.org_codename
          )[0].text,
          codename: this.departmentObject.org_codename
        },
        ...this.departmentObject
      }
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Departments', links: true, to: '/departments'},
        {text: this.textMode, links: false}
      ]
    },
    disabledPreview() {
      // return (
      //   this.departmentObject.org_codename === '' ||
      //   this.departmentObject.contents[0].title === '' ||
      //   this.departmentObject.contents[1].title === ''
      // )
      return true
    },
    ...mapState('department', [
      'codeErrorMessage',
      'departmentObject',
      'isDelete',
      'isLoading',
      'orgObject',
      'symptomaticItems',
      'contentRepeaterList',
      'isLoadingContentRepeater',
      'duplicateContent',
      'pageLink',
      'filterHospital'
    ])
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
    this.resetList()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)

    if (this.isEditMode) {
      await this.setDepartmentId(this.$route.params.id)
      // scroll to top
      window.scrollTo(0, 0)
    } else {
      this.resetState()
    }

    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditDepartment', [
        this.departmentObject.org_codename
      ])
    await this.setOrgInfo(isViewOnly)
  },
  mounted() {
    this.isAliasError = false
    if (this.disabledEdit) {
      this.disableQuill()
    } else {
      this.enableQuill()
    }
  },
  watch: {
    disabledEdit() {
      if (this.disabledEdit) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    },
    duplicateContent: {
      handler() {
        function deepEqualWithJSON(obj1, obj2) {
          return JSON.stringify(obj1) === JSON.stringify(obj2)
        }
        const isNotCheckDuplicate = Object.values(this.duplicateContent).every(
          v => v === false
        )
        this.disabledReset =
          deepEqualWithJSON(this.departmentObject, this.packageObjOld) &&
          isNotCheckDuplicate
      },
      deep: true
    },
    departmentObject: {
      handler() {
        function deepEqualWithJSON(obj1, obj2) {
          return JSON.stringify(obj1) === JSON.stringify(obj2)
        }
        const isNotCheckDuplicate = Object.values(this.duplicateContent).every(
          v => v === false
        )
        this.disabledReset =
          deepEqualWithJSON(this.departmentObject, this.packageObjOld) &&
          isNotCheckDuplicate
      },
      deep: true
    }
  },
  methods: {
    addRepeater() {
      this.setDepartmentId(this.departmentObject.id)
      this.scrollToTop()
      this.$router.push({name: 'repeater-create'})
    },
    clickPackageLink() {
      window.open(this.pageLink, '_blank')
    },
    validAlias(text) {
      if (
        !isEnglishAlphabet(text) &&
        !isThaiAlphabet(text) &&
        !isAliasAllow(text)
      ) {
        return false
      } else {
        return true
      }
    },
    setCheckedAlias(val) {
      this.setAlias(val)
    },
    pasteData(event) {
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        'text'
      )
      if (!this.validAlias(pastedText)) {
        event.preventDefault()
      }
    },
    isNotSpecialChar(event) {
      if (!this.validAlias(event.key)) {
        event.preventDefault()
      }
    },
    removeTag(item) {
      this.selectedTags.splice(this.selectedTags.indexOf(item), 1)
      this.assignTags(this.selectedTags)
    },
    assignTags(values) {
      this.departmentObject.tags = values.map(v => ({value: v}))
    },
    handleInputTags(values) {
      this.assignTags(values)
    },
    removeSymptomaticItem(item) {
      this.selectedSymptomaticItems.splice(
        this.selectedSymptomaticItems.indexOf(item),
        1
      )
      this.assignSymptomatics(this.selectedSymptomaticItems)
    },
    assignSymptomatics(values) {
      this.departmentObject.symptomatic_ids = values.map(v => ({
        symptomatic_id: v.value
      }))
    },
    handleInputSymptomatics(values) {
      this.assignSymptomatics(values)
    },
    changedSymptomatics(newItems) {
      this.selectedSymptomaticItems = newItems.filter(item =>
        this.symptomaticItems.some(
          existingItem => existingItem.value === item.value
        )
      )
      if (this.selectedSymptomaticItems.length == 0) {
        this.symptomaticError = 'Symptomatic Area is required'
      } else {
        this.symptomaticError = ''
      }
    },
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteDepartment', [
        this.departmentObject.org_codename
      ])
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'department-list'
      })
    },
    async onRemove() {
      this.setDeleteId(this.$route.params.id)
      this.setDeletePopup(true)
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.departmentObject.org_codename = value[0]
        this.setFilterHospital(value[0])
        this.setSymptomaticItems()
        this.selectedSymptomaticItems = []
        this.departmentObject.alias = ''
        this.$store.state.department.pageLink = ''
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    closeDeleteErrorDialog() {
      this.isDeleteError = false
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()
      if (isSuccess) {
        this.$router.push({
          name: 'department-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    showFailSnackbar(action) {
      this.snackTitle = action
    },
    disableQuill() {
      this.$refs.quillTh.quill.enable(false)
      this.$refs.quillEn.quill.enable(false)
      this.$refs.quillTh.quill.root.dataset.placeholder = ''
      this.$refs.quillEn.quill.root.dataset.placeholder = ''
    },
    enableQuill() {
      this.$refs.quillTh.quill.enable(true)
      this.$refs.quillEn.quill.enable(true)
      this.$refs.quillTh.quill.root.dataset.placeholder =
        'Department description here ...'
      this.$refs.quillEn.quill.root.dataset.placeholder =
        'Department description here ...'
    },
    // syncSelectedData(data) {
    //   this.departmentObject.orgs = convertSelectedData(
    //     data,
    //     this.orgObject,
    //     this.departmentObject.orgs
    //   )
    // },
    onPreview() {
      this.isPreview = true
    },
    async onSave() {
      if (this.selectedSymptomaticItems.length == 0) {
        this.symptomaticError = 'Symptomatic Area is required'
      }

      await this.coverImageRules(
        this.departmentObject.contents[0].code,
        this.departmentObject.contents[0].cover_image
      )
      await this.coverImageRules(
        this.departmentObject.contents[1].code,
        this.departmentObject.contents[1].cover_image
      )

      if (this.validate()) {
        if (this.isEditMode) {
          const status = await this.updateDepartment({
            id: this.$route.params.id,
            pkg: this.departmentObject
          })
          if (status) {
            this.$router.push({
              name: 'department-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
          } else {
            return (this.isError = true)
          }
        } else {
          const tempAlias = this.departmentObject.alias
          const tempSymptoms = this.selectedSymptomaticItems
          const tempPageLink = this.pageLink
          const status = await this.create(this.departmentObject)
          if (status) {
            this.$refs.form.resetValidation()
            this.resetState()
            this.$router.push({
              name: 'department-list',
              query: {isCreateSuccess: true, snackTitle: 'created'}
            })
          } else {
            this.departmentObject.alias = tempAlias
            this.selectedSymptomaticItems = tempSymptoms
            this.$store.state.department.pageLink = tempPageLink
            return (this.isError = true)
          }
        }
      }
    },
    removeImageThai() {
      this.departmentObject.contents[0].logo = ''
    },
    removeImageEn() {
      this.departmentObject.contents[1].logo = ''
    },
    updateImageThai(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.isImageError = true
          this.imageErrorMessage = 'Only support image file!'
          return
        } else if (!file || file.size > 1040000) {
          this.isImageError = true
          this.imageErrorMessage =
            'Department image size should be less than 1 MB!'
          return
        } else {
          this.setImageThai(file)
          this.isImageError = false
          this.imageErrorMessage = ''
          return
        }
      } else {
        this.departmentObject.contents[0].logo = ''
      }
    },
    updateImageEn(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.isImageErrorEn = true
          this.imageErrorMessageEn = 'Only support image file!'
          return
        } else if (!file || file.size > 1040000) {
          this.isImageErrorEn = true
          this.imageErrorMessageEn =
            'Department image size should be less than 1 MB!'
          return
        } else {
          this.setImageEng(file)
          this.isImageErrorEn = false
          this.imageErrorMessageEn = ''
          return
        }
      } else {
        this.departmentObject.contents[1].logo = ''
      }
    },
    updateCoverImage(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.isCoverImageError = true
          this.coverImageErrorMessage = 'Only support image file!'
          return
        } else if (!file || file.size > 1040000) {
          this.isCoverImageError = true
          this.coverImageErrorMessage =
            'Department image size should be less than 1 MB!'
          return
        } else {
          this.setCoverImageThai(file)
          this.isCoverImageError = false
          this.coverImageErrorMessage = ''
          return
        }
      } else {
        this.departmentObject.contents[0].cover_image = ''
      }
    },
    updateCoverImageEn(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.isCoverImageErrorEn = true
          this.coverImageErrorMessageEn = 'Only support image file!'
          return
        } else if (!file || file.size > 1040000) {
          this.isCoverImageErrorEn = true
          this.coverImageErrorMessageEn =
            'Department image size should be less than 1 MB!'
          return
        } else {
          this.setCoverImageEn(file)
          this.isCoverImageErrorEn = false
          this.coverImageErrorMessageEn = ''
          return
        }
      } else {
        this.departmentObject.contents[0].cover_image = ''
      }
    },
    removeCoverImage() {
      this.departmentObject.contents[0].cover_image = ''
    },
    removeCoverImageEn() {
      this.departmentObject.contents[1].cover_image = ''
    },
    closePreview() {
      this.isPreview = false
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    validate() {
      if (
        this.$refs.form.validate() &&
        !this.isCoverImageError &&
        !this.isCoverImageErrorEn
      ) {
        return true
      } else {
        return false
      }
    },
    validateSymptomatic() {
      return this.symptomaticError === '' || this.symptomaticError
    },
    coverImageRules(lang, val) {
      if (this.isEditMode) {
        if (lang == 'th') {
          this.isCoverImageError = false
          this.coverImageErrorMessage = ''
        } else {
          this.isCoverImageErrorEn = false
          this.coverImageErrorMessageEn = ''
        }

        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        if (lang == 'th') {
          this.isCoverImageError = true
          this.coverImageErrorMessage = 'Image is required'
        } else {
          this.isCoverImageErrorEn = true
          this.coverImageErrorMessageEn = 'Image is required'
        }
        return
      } else {
        if (!val || val === '') {
          if (lang == 'th') {
            this.isCoverImageError = true
            this.coverImageErrorMessage = 'Image is required'
          } else {
            this.isCoverImageErrorEn = true
            this.coverImageErrorMessageEn = 'Image is required'
          }
          return
        }
        if (lang == 'th') {
          this.isCoverImageError = false
          this.coverImageErrorMessage = ''
        } else {
          this.isCoverImageErrorEn = false
          this.coverImageErrorMessageEn = ''
        }
        return
      }
    },

    duplicateThaiContent() {
      if (this.duplicateContent.department) {
        this.departmentObject.contents[1].title = this.departmentObject.contents[0].title
        this.departmentObject.contents[1].logo = this.departmentObject.contents[0].logo
        this.departmentObject.contents[1].cover_image = this.departmentObject.contents[0].cover_image
        this.departmentObject.contents[1].description = this.departmentObject.contents[0].description
        this.departmentObject.contents[1].cover_image_alt = this.departmentObject.contents[0].cover_image_alt
        this.departmentObject.contents[1].logo_alt = this.departmentObject.contents[0].logo_alt
        this.departmentObject.contents[1].location = this.departmentObject.contents[0].location
      } else {
        this.departmentObject.contents[1].title = null
        this.departmentObject.contents[1].logo = ''
        this.departmentObject.contents[1].cover_image = ''
        this.departmentObject.contents[1].description = ''
        this.departmentObject.contents[1].cover_image_alt = ''
        this.departmentObject.contents[1].logo_alt = ''
        this.departmentObject.contents[1].location = ''
      }

      this.coverImageRules(
        this.departmentObject.contents[1].code,
        this.departmentObject.contents[1].cover_image
      )
    },
    ...mapActions('department', [
      'create',
      'deletePkg',
      'resetList',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setDepartmentId',
      'setEditMode',
      'setImageThai',
      'setImageEng',
      'setOrgInfo',
      'update',
      'updateDepartment',
      'upload',
      'setSymptomaticItems',
      'setAlias',
      'setCoverImageThai',
      'setCoverImageEn',
      'setFilterHospital'
      // 'setFilterHospitalWebsite'
    ])
  }
}
</script>

<style scoped>
.symptomatic-box-error {
  color: #ff5252 !important;
}
.v-input--is-readonly {
  background: #ecf0f3 !important;
}
</style>
